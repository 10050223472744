import DeviceDetector from "device-detector-js";
import { Notifier } from "@airbrake/browser";

window.deviceDetector = new DeviceDetector();
window.device = window.deviceDetector.parse(navigator.userAgent);

if (!window.device.bot && window.env.JEKYLL_ENV === "production") {
  window.airbrake = new Notifier({
    projectId: window.env.AIRBRAKE_PROJECT_ID,
    projectKey: window.env.AIRBRAKE_PROJECT_KEY,
    host: "https://panel.sutty.nl",
  });

  console.originalError = console.error;
  console.error = (...e) => {
    window.airbrake.notify(e.join(" "));
    return console.originalError(...e);
  };
}

import "core-js/stable";
import "regenerator-runtime/runtime";

// Turbo acelera la navegación al no tener que recargar todo el JS y CSS
// de la página, con lo que se siente más rápida y "nativa".
//
// Cambiamos de turbolinks a turbo porque turbo soporta la función
// fetch(), que luego es interceptada por el SW para obtener las
// direcciones localmente.
import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

document.addEventListener("turbo:load", (event) => {
  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      a.rel = "noopener";
      a.target = "_blank";
    });
});
